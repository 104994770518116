









import { Component, Vue } from 'vue-property-decorator'

import EventSubscriptionsRulesTable from '@/partials/tables/Events/Subscriptions/Rules/index.vue'

@Component({
  components: {
    EventSubscriptionsRulesTable
  }
})
export default class SubscriptionRefundIndex extends Vue {

}
